<template>
	<default-page-layout>
		<article class="wallet-connect">
			<section class="wallet-connect__header">
				<span class="h7 wallet-connect__header--text">Connect your wallet</span>
			</section>
			<div class="wallet-connect__content">
				<blue-card-button
					v-if="false"
					class="wallet-connect__content--button"
					title="walletconnect.selector.content.title1"
					content="walletconnect.selector.content.content1"
					:img="rngLogo">
				</blue-card-button>
				<blue-card-button
					@click="pushtoQR"
					class="wallet-connect__content--button"
					title="WalletConnect"
					content="Scan QR or copy address to connect"
					:img="wcLogo" />
			</div>
		</article>
	</default-page-layout>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import {useWalletConnectStore} from "../../stores/walletconnect.store";
import {useBlockchainsStore} from "../../stores/blockchains.store";
import {useRoute, useRouter} from "vue-router";
import {useStorage} from "@vueuse/core";
import BlueCardButton from "../../components/buttons/BlueCardButton.vue";
import DefaultPageLayout from "../../layouts/DefaultPageLayout.vue";
import {createWeb3Modal, defaultConfig, Web3Modal} from "@web3modal/ethers5";

const walletConnectStore = useWalletConnectStore()
const blockchainsStore = useBlockchainsStore()
const projectId = import.meta.env.VITE_WALLETCONNETC_PROJECT_ID

const route = useRoute()

const metadata = {
	name: 'Renegade Swap',
	description: 'Swap and bridge with Renegade',
	url: import.meta.env.VITE_APP_DOMAIN_ORIGIN,
	icons: ['https://avatars.mywebsite.com/'],
}

const ethersConfig = defaultConfig({
	metadata,
	defaultChainId: 1,
})

const currentStep = ref(0)
const router = useRouter()
let modal: Web3Modal | null = null

const destinationRoute = useStorage('connectDestinationRoute', '/', sessionStorage)

const rngLogo = new URL('/src/assets/images/logorng.svg', import.meta.url).href
const wcLogo = new URL('/src/assets/images/walletconnect.svg', import.meta.url).href

onBeforeMount(() => {
	// todo: add placeholders

	modal = createWeb3Modal({
		ethersConfig,
		chains: [...blockchainsStore.mainnets, ...blockchainsStore.testnets],
		projectId,
		enableAnalytics: false, // Optional - defaults to your Cloud configuration
		enableOnramp: false, // Optional - false as default
    featuredWalletIds: [
      '2bc58b1c76e6572fa6cd68c3a448128ce90f7442d563f02e9f9db3b58d01d810',
      '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
      'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96'
    ]
	})

	walletConnectStore.modal = modal

	walletConnectStore.modal.subscribeProvider((newState) => {
    console.log(newState)
		if (newState.isConnected && route.fullPath === '/connect') {
			walletConnectStore.walletAddress = newState.address
			router.push({ path: destinationRoute.value })
		}
	})
})

function pushtoQR() {
	walletConnectStore.disconnect().then(() => walletConnectStore.modal?.open())

	// ionRouter.push('/tabs/connect/qr')
}
</script>
<style scoped lang="sass">
@import "src/assets/sass/abstracts/variables"
@import "src/assets/sass/abstracts/mixins"
.wallet-connect
	padding: 24px 50px
	height: calc(100vh - var(--tabs-height) - var(--header-height))

	&__header
		display: flex
		gap: 50px
		align-items: center
		justify-content: space-between

		&--text
			font-weight: 700
			color: $grey-4
			font-size: 16px
			line-height: 21.6px
			color: rgba(243, 231, 248, 1)

	&__content
		display: flex
		justify-content: center
		flex-direction: column
		gap: 16px
		margin-top: 24px

		&--button
			border-radius: 8px
			border: 0.5px solid rgba(183, 159, 250, 0.28)
			height: 74px
</style>
